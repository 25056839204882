<template>
  <!-- Coming soon page-->
  <div
    class="misc-wrapper pt-0 mt-0"
    style="align-items: initial;"
  >
    <div class="misc-inner px-2  pt-0 mt-0">
      <div class="w-100">
        <h1
          class="mb-1"
          style="font-weight: 700;"
        >
          User Agreement
        </h1>
        <p class="mb-3 ">
          The following terms and conditions govern all use of the UptimeRobot website and all content, services and products available at or through the website. The Website is owned and operated by Uptime Robot Service Provider Ltd. ("UptimeRobot" -a trademark of the company-). The Website is offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other <span class="text-primary">operating rules,</span> policies (including, without limitation, UptimeRobot's Privacy Policy) and procedures that may be published from time to time on this Site by UptimeRobot (collectively, the "Agreement"). Please read this Agreement carefully before accessing or using the Website. By accessing or using any part of the web site, you agree to become bound by the terms and conditions of this agreement. <b
            class=""
            style="font-weight: 700;"
          >This is bold text or phrase</b> the terms and conditions of this agreement, then you may not access the Website or use any services. If these terms and conditions are considered an offer by UptimeRobot, acceptance is expressly limited to these terms.
        </p>
      </div>
      <div class="w-100">
        <h1
          class="mb-1"
          style="font-weight: 700;"
        >
          Heading 1
        </h1>
        <p class="mb-3 ">
          <b
            class=""
            style="font-weight: 700;"
          >1. Your Uptimerobot Account and Site.</b><br>
          If you have an account on the Website, you are responsible for maintaining the security of your account (e-mail used, password or api keys) and you are fully responsible for all activities that occur under the account and any other actions taken in connection with the account. You must not describe or assign keywords to your blog in a misleading or unlawful manner, including in a manner intended to trade on the name or reputation of others, and UptimeRobot may change or remove any description or keyword that it considers inappropriate or unlawful, or otherwise likely to cause UptimeRobot liability. You must immediately notify.
        </p>
      </div>
    </div>
  </div>
<!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BForm, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BLink,
    BForm,
    BButton,
    BFormInput,
    BImg,
  },
  data() {
    return {
      sideImg: require('@/assets/images/logo/logo-seocloud.svg'),
      downImg: require('@/assets/images/pages/coming-soon.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>

<style>
.img-logo {
  width: 340px;
  height: 40px;
  position: relative;
  top: 25px;
  left: 20px;
}
body {
  background: rgb(24, 30, 56) !important;
}
</style>
